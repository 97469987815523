<template>
  <div id="body">
    <div class="headerBox">
		<div style="display:flex;justify-content: space-between;">
      <p style="text-align: left;padding-left: 20px;">广告商申请</p>
		  <el-button type="primary" class="btn" @click="authLog" style="height: 40px;">审核记录</el-button></div>
    </div>
    <el-table :data="advData" v-loading="loading">
      <el-table-column prop="id" label="序号" align="center" width="80"></el-table-column>
      <el-table-column prop="company_name" label="广告商名称" align="center"> </el-table-column>
      <el-table-column prop="company_type" label="证件类型" align="center">
		  <template slot-scope="scope">
			  {{scope.company_type == 1 ? '企业' : '个体工商户'}}
		  </template>
	  </el-table-column>
      <el-table-column prop="company_person" label="法人姓名" align="center"></el-table-column>
      <el-table-column prop="company_tel" label="公司电话" align="center"></el-table-column>
      <el-table-column prop="create_date" label="申请时间" align="center" width="150"></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="detail(scope.row)">审核</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="currpage" :page-size="pagesize" background
        layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top:15px">
      </el-pagination>
    </div>
    <el-dialog title="广告商认证审核" :visible.sync="dialogVisible" width="40%" center top="5px">
      <div class="content">
        <p>
          <span>公司名称：</span>
          <span>{{data.company_name}}</span>
        </p>
      </div>
      <div class="content">
        <p>
          <span>申请时间：</span>
          <span>{{data.create_date}}</span>
        </p>
      </div>
      <div class="content">
        <p>
          <span>证件类型：</span>
          <span>{{data.company_type == 1 ? '企业' : '个体工商户'}}</span>
        </p>
      </div>
      <div class="content">
        <p>
          <span>展示图片：</span>
		  <span><img :src="data.pic" alt="图片" width="135px" height="240px"></span>
        </p>
      </div>
	  
	  <div class="content">
	    <p>
	      <span>统一社会信用代码：</span>
	      <span>{{data.company_id}}</span>
	    </p>
	  </div>
	  
	  <div class="content">
	    <p>
	      <span>证件有效期：</span>
	      <span>{{data.id_time}}---{{data.id_time_end}}</span>
	    </p>
	  </div>
	  
	  <div class="content">
	    <p>
	      <span>注册地：</span>
	      <span>{{data.province_name + data.city_name}}</span>
	    </p>
	  </div>
	  
	  <div class="content">
	    <p>
	      <span>法人手机号：</span>
	      <span>{{data.tel}}</span>
	    </p>
	  </div>
	  
	  <div class="content">
	    <p>
	      <span>公司电话：</span>
	      <span>{{data.company_tel}}</span>
	    </p>
	  </div>
	  
	  <div class="content">
	    <p>
	      <span>法人姓名：</span>
	      <span>{{data.company_person}}</span>
	    </p>
	  </div>
      <span slot="footer">
        <el-button type="success" @click="handleAgree">同意</el-button>
        <el-button type="danger" @click="handleReject">拒绝</el-button>
      </span>
    </el-dialog>
	<el-dialog title="拒绝审批" :visible.sync="rejectVisible" width="40%" center top="5px">
	  <div class="content">
	    <p>
	      <span>拒绝理由：</span>
	    </p>
	  </div>
	  <div class="content">
	    <p style="width:100%;text-align: center;">
	      <el-input style="width: 80%;margin: 0 auto;" :rows="6"
 	        type="textarea"
	        v-model="remark" />
	    </p>
	  </div>
	  <span slot="footer">
	    <el-button type="success" @click="handleOkReject">确认</el-button>
	    <el-button @click="handleCancelReject">取消</el-button>
	  </span>
	</el-dialog>
  </div>
</template>

<script>
  import {
    advAuthList,
    advAuth,
    advExamine
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        advData: [], // 广告主列表数据
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        loading: false, //加载框
        imgShow: [], //显示的展示图片
        dialogVisible: false, //广告详情弹窗
        data: {}, //广告详情数据
		remark: '',   // 拒绝理由
		rejectVisible: false
      }
    },
    mounted() {
      this.advList(1) // 获取广告主列表
    },
    methods: {
		authLog() {
			this.$router.push({
				name: 'advAuthLog'
			})
		},
		//取消拒绝
		handleCancelReject() {
			this.rejectVisible = false
			this.remark = ''
		},
		
		// 发送拒绝
		handleOkReject() {
			if (!this.remark) {
				this.$message.error('请填写拒绝理由')
				return
			}
			advAuth({
				id: this.data.id,
				status: 2,
				remark: this.remark
			}).then(res=>{
				this.remark = ''
				this.$message.success("审核已拒绝")
				this.advList(1)
				this.rejectVisible = false //打开弹窗
			})
		},
		
		// 同意
		handleAgree() {
			advAuth({
				id: this.data.id,
				status: 1
			}).then(res=>{
				this.$message.success("审核已通过")
				this.advList(1)
				this.dialogVisible = false //打开弹窗
			})
		},
		// 拒绝
		handleReject() {
			this.dialogVisible = false
			this.rejectVisible = true
		},
      advList(currpage) { // 广告主列表
        this.loading = true
        const data = {}
        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数
        data["stage"] = '100' //审核状态 ( 1待审核2已通过3已拒绝 100全部 )
        advAuthList(data).then(res => {
          this.CountNum = parseInt(res.data.countNum) // 总条数
          this.advData = res.data.list // 设备列表
          this.currpage = currpage //分页显示当前页
          this.loading = false
			console.log(1111,this.advData,res.data.list)
        }).catch(err => {
          console.log(err)
        })
      },
      detail(row) { // 点击广告详情
		
		this.data = row
		this.dialogVisible = true //打开弹窗
      },
      check(num) {
        let data = {
          advId: this.data.id, //广告id
          stage: num //审核状态 ( 2通过 3拒绝 )
        }

        // if(this.advData.length == 1 &&  this.currpage > 1){
        //     this.currpage = this.currpage -1;
        // }else if(this.advData.length == 1 && this.currpage == 1){
        //     this.currpage = 1;
        // }

        advExamine(data).then(res => {
          if (res.status.code == 1) {
            if (num == 2) {
              this.$message.success("广告已通过")
            } else {
              this.$message.error("广告已拒绝")
            }
            this.dialogVisible = false // 关闭弹窗
            this.advList(this.currpage) // 获取广告主列表
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        this.advList(val)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .content {
    display: flex;

    p {
      display: flex;
      width: 100%;

      span:nth-child(1) {
        width: 40%;
        text-align: right;
      }

      span:nth-child(2) {
        width: 60%;
        text-align: left;
      }
    }
  }

  .content-img {
    display: flex;
    justify-content: center;
    gap: 1%;
  }

  .content-detail {
    span {
      margin-left: 6%;
    }

    div {
      width: 50%;
      margin: 0 auto;
    }
  }

  .content-range {
    display: flex;

    p {
      display: flex;
      width: 100%;

      span:nth-child(1) {
        width: 16%;
        text-align: right;
      }

      span:nth-child(2) {
        width: 84%;
        text-align: left;
      }
    }
  }
</style>
